import { useCallback, useEffect, useState } from "react";
import { useDebounceCallback } from "@react-hook/debounce";
import { isEqual } from "lodash";
import useAsyncCompleteToast from "components/Shared/useAsyncCompleteToast";
import usePrevious from "utils/usePrevious";

type LoadOptionsCallback<Option> = (options: readonly Option[]) => void;

type UseAsyncFetchOptionsOptions<Option> = {
  cacheUsing?: unknown;
  debounceDuration?: number;
  fetchOptions: (inputValue: string) => Promise<Option[] | readonly Option[]>;
  minInputLength?: number;
};

/**
 * A hook to be used with the `AsyncSelect` `react-select` component that
 * primarily simplifies the handling of debounced option fetching.
 */
export default function useAsyncFetchOptions<Option>({
  cacheUsing,
  debounceDuration = 300,
  fetchOptions: performFetch,
  minInputLength = 2,
}: UseAsyncFetchOptionsOptions<Option>) {
  const [cacheKey, setCacheKey] = useState<true | number>(true);

  const { showErrorToast } = useAsyncCompleteToast();

  const prevCacheUsing = usePrevious(cacheUsing);

  useEffect(() => {
    if (
      (cacheUsing !== undefined || prevCacheUsing !== undefined) &&
      !isEqual(cacheUsing, prevCacheUsing)
    ) {
      setCacheKey((prev) => (typeof prev === "number" ? prev + 1 : 1));
    }
  }, [cacheUsing, prevCacheUsing]);

  const fetchOptions = useCallback(
    (inputValue: string, callback: LoadOptionsCallback<Option>) => {
      if (!inputValue || inputValue.length < minInputLength)
        return callback([]);

      performFetch(inputValue)
        .then((options) => {
          callback(options);
        })
        .catch((reason) => {
          showErrorToast(reason, {
            title: "An error occurred loading options",
          });
          callback([]);
        });
    },
    [performFetch, showErrorToast, minInputLength],
  );

  const debouncedFetchOptions = useDebounceCallback(
    fetchOptions,
    debounceDuration,
    false,
  );

  const loadOptions = useCallback(
    (inputValue: string, callback: LoadOptionsCallback<Option>) => {
      debouncedFetchOptions(inputValue, callback);
    },
    [debouncedFetchOptions],
  );

  return { loadOptions, cacheOptions: cacheKey };
}
