var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"01cf8cef2f580be50b711beaebeb069d854ac320"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";
import getEnv from "utils/browserEnv";
import { SENTRY_DSN } from "utils/sentrySharedConfig";

Sentry.init({
  dsn: SENTRY_DSN,

  environment: getEnv(),

  integrations: [
    new BrowserTracing({
      // We customize this because the default (`/^\//`) also matches
      // protocol-less URLs like `//domain.com`, which can cause CORS issues
      // with third-party libraries.
      tracingOrigins: [/^\/(?:[^/]|$)/],
    }),
  ],

  tracesSampleRate: 0.25,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
