import { ReactNode } from "react";
import { Box, BoxProps, useToken } from "@chakra-ui/react";
import SmCactus from "public/assets/smCactus.svg?react";
import TwoCacti from "public/assets/2cactiWithStar.svg?react";
import { CONTENT_FULL_HEIGHT } from "components/Layout/constants";

export type Props = BoxProps & {
  children: ReactNode;
  graphicsProps?: Partial<BoxProps>;
  isWithNavBar?: boolean;
};

function CactusBackground({
  children,
  graphicsProps,
  isWithNavBar = false,
  ...rest
}: Props) {
  const [yellowGreen] = useToken("colors", ["yellowGreen"]);

  return (
    <Box
      backgroundColor="softBlue"
      position="relative"
      minH={isWithNavBar ? CONTENT_FULL_HEIGHT : "100vh"}
      overflowX="clip"
      {...rest}
    >
      <Box
        w="13.5rem"
        h="13.5rem"
        background={`repeating-linear-gradient(
          to bottom,
          ${yellowGreen} 0,
          ${yellowGreen} 4px,
          transparent 4px,
          transparent 8px
        )`}
        pos="absolute"
        top="7%"
        right="-3%"
        transform="rotate(-30deg)"
        {...graphicsProps}
      />

      <Box pos="absolute" top="5%" left="-7%" {...graphicsProps}>
        <Box w="23rem" h="23rem" borderRadius="50%" bg="lightCyan" />
        <Box
          w="8.5rem"
          h="8.5rem"
          borderRadius="50%"
          borderColor="lightPink"
          borderWidth={3}
          position="absolute"
          top="10%"
          right="-15%"
        />
      </Box>

      <Box
        as={SmCactus}
        pos="absolute"
        bottom="0"
        left="25%"
        {...graphicsProps}
      />

      <Box
        as={TwoCacti}
        pos="absolute"
        bottom="0"
        right="10%"
        {...graphicsProps}
      />
      {children}
    </Box>
  );
}

export default CactusBackground;
