export class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, AssertionError.prototype);
  }
}

export function assertPresence<V>(
  variable: V | null | undefined,
  message = "Received a falsy value for a required variable!",
): asserts variable is V {
  if (!variable && variable !== 0 && variable !== false)
    throw new AssertionError(message);
}

export function assertBrandId(
  brandId: UUID | null | undefined,
): asserts brandId is UUID {
  return assertPresence(brandId, "Brand ID required but unavailable!");
}

export function assertIsArray(value: unknown): asserts value is unknown[] {
  if (!Array.isArray(value)) {
    throw new AssertionError("Expected an array");
  }
}

export function getAssertedArray(value: unknown): unknown[] {
  assertIsArray(value);
  return value;
}

export function getAssertedArrayOfType<R>(
  value: unknown,
  assertionFunction: (value: unknown) => asserts value is R,
): R[] {
  return getAssertedArray(value).map((v) => {
    assertionFunction(v);
    return v;
  });
}
