/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: ISODateTime;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: GraphQLGenericScalar;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: UUID;
};

export type AnalyticsSkuMapping = {
  __typename: 'AnalyticsSkuMapping';
  brandId: Scalars['UUID'];
  dataSourceProviderName: Scalars['String'];
  products: Array<AnalyticsSkuMappingProduct>;
  skuComponentTypes: Array<SkuComponentType>;
};

export type AnalyticsSkuMappingProduct = {
  __typename: 'AnalyticsSkuMappingProduct';
  extraIdentifiers: Array<AnalyticsSkuMappingProductExtraIdentifier>;
  productName: Scalars['String'];
  skuComponentId: Maybe<Scalars['UUID']>;
  tenantId: Scalars['UUID'];
  upc: Maybe<Scalars['String']>;
};

export type AnalyticsSkuMappingProductExtraIdentifier = {
  __typename: 'AnalyticsSkuMappingProductExtraIdentifier';
  label: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export type BrandUser = {
  __typename: 'BrandUser';
  brandId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['UUID'];
};

export type CreatePitchableUnsubscribeMutationInput = {
  brandId?: InputMaybe<Scalars['UUID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddressId: Scalars['UUID'];
  isUnsubscribingFromAll?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePitchableUnsubscribeMutationPayload = {
  __typename: 'CreatePitchableUnsubscribeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
};

export type DeletePitchableUnsubscribeMutationInput = {
  brandId?: InputMaybe<Scalars['UUID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddressId: Scalars['UUID'];
  isUnsubscribingFromAll?: InputMaybe<Scalars['Boolean']>;
};

export type DeletePitchableUnsubscribeMutationPayload = {
  __typename: 'DeletePitchableUnsubscribeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
};

export type ForwardReceivedEmailToAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  fromAddress?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  receivedEmailMessageId: Scalars['UUID'];
  via?: InputMaybe<Scalars['String']>;
};

export type ForwardReceivedEmailToAddressPayload = {
  __typename: 'ForwardReceivedEmailToAddressPayload';
  clientMutationId: Maybe<Scalars['String']>;
  forwardedEmailMessageId: Maybe<Scalars['UUID']>;
};

export type ForwardReceivedPitchableEmailToBrandInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  receivedPitchableEmailMessageId: Scalars['UUID'];
  skipExistingCheck?: InputMaybe<Scalars['Boolean']>;
};

export type ForwardReceivedPitchableEmailToBrandPayload = {
  __typename: 'ForwardReceivedPitchableEmailToBrandPayload';
  clientMutationId: Maybe<Scalars['String']>;
  forwardedEmailMessageId: Maybe<Scalars['UUID']>;
};

export type GetMetabaseToken = {
  __typename: 'GetMetabaseToken';
  token: Maybe<Scalars['String']>;
};

export type InviteBrandUser = {
  __typename: 'InviteBrandUser';
  brandUser: Maybe<BrandUser>;
};

export type Mutation = {
  __typename: 'Mutation';
  createPitchableUnsubscribe: Maybe<CreatePitchableUnsubscribeMutationPayload>;
  deletePitchableUnsubscribe: Maybe<DeletePitchableUnsubscribeMutationPayload>;
  forwardReceivedEmailToAddress: Maybe<ForwardReceivedEmailToAddressPayload>;
  forwardReceivedPitchableEmailToBrand: Maybe<ForwardReceivedPitchableEmailToBrandPayload>;
  getMetabaseToken: Maybe<GetMetabaseToken>;
  inviteBrandUser: Maybe<InviteBrandUser>;
  pitchableRequestSample: Maybe<PitchableRequestSampleMutationPayload>;
  refreshToken: Maybe<Refresh>;
  resendBrandUserInvite: Maybe<ResendBrandUserInvite>;
  resetPassword: Maybe<ResetPassword>;
  resetPasswordConfirm: Maybe<ResetPasswordConfirm>;
  revokeToken: Maybe<Revoke>;
  sendPitchableCampaignToContact: Maybe<SendPitchableCampaignToContactMutationPayload>;
  sendPitchableCampaignToEmailAddress: Maybe<SendPitchableCampaignToEmailAddressMutationPayload>;
  sendPitchableCampaignToValidDestinations: Maybe<SendPitchableCampaignToValidDestinationsMutationPayload>;
  /**
   * Subclass the built-in ObtainJSONWebToken view so that we can
   * add the user object to the mutation response.
   */
  tokenAuth: Maybe<ObtainJSONWebToken>;
};


export type MutationcreatePitchableUnsubscribeArgs = {
  input: CreatePitchableUnsubscribeMutationInput;
};


export type MutationdeletePitchableUnsubscribeArgs = {
  input: DeletePitchableUnsubscribeMutationInput;
};


export type MutationforwardReceivedEmailToAddressArgs = {
  input: ForwardReceivedEmailToAddressInput;
};


export type MutationforwardReceivedPitchableEmailToBrandArgs = {
  input: ForwardReceivedPitchableEmailToBrandInput;
};


export type MutationgetMetabaseTokenArgs = {
  brandId: Scalars['UUID'];
};


export type MutationinviteBrandUserArgs = {
  brandId: Scalars['UUID'];
  user: UserInput;
};


export type MutationpitchableRequestSampleArgs = {
  input: PitchableRequestSampleMutationInput;
};


export type MutationrefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationresendBrandUserInviteArgs = {
  brandUserId: Scalars['UUID'];
};


export type MutationresetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationresetPasswordConfirmArgs = {
  id: Scalars['String'];
  newPassword: Scalars['String'];
  reNewPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationrevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationsendPitchableCampaignToContactArgs = {
  input: SendPitchableCampaignToContactMutationInput;
};


export type MutationsendPitchableCampaignToEmailAddressArgs = {
  input: SendPitchableCampaignToEmailAddressMutationInput;
};


export type MutationsendPitchableCampaignToValidDestinationsArgs = {
  input: SendPitchableCampaignToValidDestinationsMutationInput;
};


export type MutationtokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/**
 * Subclass the built-in ObtainJSONWebToken view so that we can
 * add the user object to the mutation response.
 */
export type ObtainJSONWebToken = {
  __typename: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type PitchableRequestSampleInfo = {
  __typename: 'PitchableRequestSampleInfo';
  brandName: Scalars['String'];
  formData: Maybe<PitchableRequestSampleInfoFormData>;
};

export type PitchableRequestSampleInfoFormData = {
  __typename: 'PitchableRequestSampleInfoFormData';
  city: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  retailerName: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type PitchableRequestSampleMutationInput = {
  changedFields?: InputMaybe<Array<Scalars['String']>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  pitchableCampaignEmailId: Scalars['UUID'];
  pitchableCampaignRetailerContactId: Scalars['UUID'];
  shippingDetails: PitchableRequestSampleShippingDetails;
};

export type PitchableRequestSampleMutationPayload = {
  __typename: 'PitchableRequestSampleMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PitchableRequestSampleShippingDetails = {
  city: Scalars['String'];
  email: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  retailerName: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type PitchableUnsubscribe = {
  __typename: 'PitchableUnsubscribe';
  brandId: Maybe<Scalars['UUID']>;
  brandName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailAddressId: Scalars['UUID'];
  isBrandUnsubscribed: Maybe<Scalars['Boolean']>;
  isUnsubscribed: Scalars['Boolean'];
};

export type Query = {
  __typename: 'Query';
  analyticsSkuMappings: Maybe<AnalyticsSkuMapping>;
  pitchableRequestSampleInfo: Maybe<PitchableRequestSampleInfo>;
  pitchableUnsubscribe: PitchableUnsubscribe;
  testSentry: Maybe<Scalars['String']>;
};


export type QueryanalyticsSkuMappingsArgs = {
  brandId: Scalars['UUID'];
  dataSourceProviderName: Scalars['String'];
};


export type QuerypitchableRequestSampleInfoArgs = {
  pitchableCampaignEmailId: Scalars['UUID'];
  pitchableCampaignRetailerContactId?: InputMaybe<Scalars['UUID']>;
};


export type QuerypitchableUnsubscribeArgs = {
  emailAddressId?: InputMaybe<Scalars['UUID']>;
  pitchableCampaignRetailerContactId?: InputMaybe<Scalars['UUID']>;
};

export type Refresh = {
  __typename: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type ResendBrandUserInvite = {
  __typename: 'ResendBrandUserInvite';
  brandUser: Maybe<BrandUser>;
};

export type ResetPassword = {
  __typename: 'ResetPassword';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordConfirm = {
  __typename: 'ResetPasswordConfirm';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Maybe<Scalars['Boolean']>;
};

export type Revoke = {
  __typename: 'Revoke';
  revoked: Scalars['Int'];
};

export type SendPitchableCampaignToContactMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  pitchableCampaignId: Scalars['UUID'];
  pitchableCampaignRetailerContactId: Scalars['UUID'];
};

export type SendPitchableCampaignToContactMutationPayload = {
  __typename: 'SendPitchableCampaignToContactMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  pitchableCampaignEmailMessageId: Scalars['UUID'];
};

export type SendPitchableCampaignToEmailAddressMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  pitchableCampaignId: Scalars['UUID'];
  salutation?: InputMaybe<Scalars['String']>;
};

export type SendPitchableCampaignToEmailAddressMutationPayload = {
  __typename: 'SendPitchableCampaignToEmailAddressMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  emailMessageId: Scalars['UUID'];
};

export type SendPitchableCampaignToValidDestinationsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  pitchableCampaignId: Scalars['UUID'];
  pitchableCampaignRetailerContactIds?: InputMaybe<Array<Scalars['UUID']>>;
};

export type SendPitchableCampaignToValidDestinationsMutationPayload = {
  __typename: 'SendPitchableCampaignToValidDestinationsMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  pitchableCampaignEmailMessageIds: Array<Scalars['UUID']>;
};

/** An enumeration. */
export enum SkuComponentType {
  CASE = 'CASE',
  INNER = 'INNER',
  UNIT = 'UNIT'
}

export type User = {
  __typename: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image: Maybe<Scalars['String']>;
  inviteAcceptedAt: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AdminPitchableCampaignAdminSendToContactMutationVariables = Exact<{
  campaignId: Scalars['UUID'];
  contactId: Scalars['UUID'];
}>;


export type AdminPitchableCampaignAdminSendToContactMutation = { __typename: 'Mutation', sendPitchableCampaignToContact: { __typename: 'SendPitchableCampaignToContactMutationPayload', pitchableCampaignEmailMessageId: UUID } | null };

export type AdminPitchableCampaignAdminSendToEmailMutationVariables = Exact<{
  campaignId: Scalars['UUID'];
  email: Scalars['String'];
  salutation?: InputMaybe<Scalars['String']>;
}>;


export type AdminPitchableCampaignAdminSendToEmailMutation = { __typename: 'Mutation', sendPitchableCampaignToEmailAddress: { __typename: 'SendPitchableCampaignToEmailAddressMutationPayload', emailMessageId: UUID } | null };

export type AdminPitchableCampaignAdminSendCampaignMutationVariables = Exact<{
  campaignId: Scalars['UUID'];
  contactIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type AdminPitchableCampaignAdminSendCampaignMutation = { __typename: 'Mutation', sendPitchableCampaignToValidDestinations: { __typename: 'SendPitchableCampaignToValidDestinationsMutationPayload', pitchableCampaignEmailMessageIds: Array<UUID> } | null };

export type AdminPitchableEmailInboxForwardToBrandMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AdminPitchableEmailInboxForwardToBrandMutation = { __typename: 'Mutation', forwardReceivedPitchableEmailToBrand: { __typename: 'ForwardReceivedPitchableEmailToBrandPayload', forwardedEmailMessageId: UUID | null } | null };

export type AdminPitchableEmailInboxForwardToAddressMutationVariables = Exact<{
  emailId: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type AdminPitchableEmailInboxForwardToAddressMutation = { __typename: 'Mutation', forwardReceivedEmailToAddress: { __typename: 'ForwardReceivedEmailToAddressPayload', forwardedEmailMessageId: UUID | null } | null };

export type AnalyticsProductsMappingsQueryVariables = Exact<{
  brandId: Scalars['UUID'];
  dataSourceProviderName: Scalars['String'];
}>;


export type AnalyticsProductsMappingsQuery = { __typename: 'Query', analyticsSkuMappings: { __typename: 'AnalyticsSkuMapping', skuComponentTypes: Array<SkuComponentType>, products: Array<{ __typename: 'AnalyticsSkuMappingProduct', productName: string, upc: string | null, skuComponentId: UUID | null, extraIdentifiers: Array<{ __typename: 'AnalyticsSkuMappingProductExtraIdentifier', label: string, value: string | null }> }> } | null };

export type InviteBrandUserMutationVariables = Exact<{
  brandId: Scalars['UUID'];
  user: UserInput;
}>;


export type InviteBrandUserMutation = { __typename: 'Mutation', inviteBrandUser: { __typename: 'InviteBrandUser', brandUser: { __typename: 'BrandUser', id: UUID, brandId: UUID, createdAt: ISODateTime, user: { __typename: 'User', id: UUID, email: string, firstName: string, lastName: string, fullName: string | null, image: string | null, inviteAcceptedAt: ISODateTime | null } } | null } | null };

export type ResendBrandUserInviteMutationVariables = Exact<{
  brandUserId: Scalars['UUID'];
}>;


export type ResendBrandUserInviteMutation = { __typename: 'Mutation', resendBrandUserInvite: { __typename: 'ResendBrandUserInvite', brandUser: { __typename: 'BrandUser', id: UUID, user: { __typename: 'User', id: UUID, email: string } } | null } | null };

export type ResetPasswordConfirmMutationVariables = Exact<{
  id: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
  reNewPassword: Scalars['String'];
}>;


export type ResetPasswordConfirmMutation = { __typename: 'Mutation', resetPasswordConfirm: { __typename: 'ResetPasswordConfirm', success: boolean | null, errors: Array<string | null> | null } | null };

export type RequestSampleInfoQueryVariables = Exact<{
  pceid: Scalars['UUID'];
  pcrcid?: InputMaybe<Scalars['UUID']>;
}>;


export type RequestSampleInfoQuery = { __typename: 'Query', pitchableRequestSampleInfo: { __typename: 'PitchableRequestSampleInfo', brandName: string, formData: { __typename: 'PitchableRequestSampleInfoFormData', retailerName: string | null, name: string | null, email: string | null, phone: string | null, line1: string | null, line2: string | null, city: string | null, state: string | null, zip: string | null } | null } | null };

export type RequestSampleMutationVariables = Exact<{
  input: PitchableRequestSampleMutationInput;
}>;


export type RequestSampleMutation = { __typename: 'Mutation', pitchableRequestSample: { __typename: 'PitchableRequestSampleMutationPayload', success: boolean } | null };

export type CreatePitchableUnsubscribeMutationVariables = Exact<{
  input: CreatePitchableUnsubscribeMutationInput;
}>;


export type CreatePitchableUnsubscribeMutation = { __typename: 'Mutation', createPitchableUnsubscribe: { __typename: 'CreatePitchableUnsubscribeMutationPayload' } | null };

export type DeletePitchableUnsubscribeMutationVariables = Exact<{
  input: DeletePitchableUnsubscribeMutationInput;
}>;


export type DeletePitchableUnsubscribeMutation = { __typename: 'Mutation', deletePitchableUnsubscribe: { __typename: 'DeletePitchableUnsubscribeMutationPayload' } | null };

export type SendResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetEmailMutation = { __typename: 'Mutation', resetPassword: { __typename: 'ResetPassword', success: boolean | null, errors: Array<string | null> | null } | null };

export type PitchableUnsubscribeVerifyQueryVariables = Exact<{
  eaid?: InputMaybe<Scalars['UUID']>;
  pcrcid?: InputMaybe<Scalars['UUID']>;
}>;


export type PitchableUnsubscribeVerifyQuery = { __typename: 'Query', pitchableUnsubscribe: { __typename: 'PitchableUnsubscribe', brandId: UUID | null, brandName: string | null, email: string, emailAddressId: UUID, isUnsubscribed: boolean, isBrandUnsubscribed: boolean | null } };

export type GetTokenMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GetTokenMutation = { __typename: 'Mutation', auth: { __typename: 'ObtainJSONWebToken', token: string, refreshToken: string, refreshExpiresIn: number } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename: 'Mutation', refreshToken: { __typename: 'Refresh', token: string, payload: GraphQLGenericScalar, refreshToken: string, refreshExpiresIn: number } | null };

export type RevokeTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RevokeTokenMutation = { __typename: 'Mutation', revokeToken: { __typename: 'Revoke', revoked: number } | null };

export type GetMetabaseTokenMutationVariables = Exact<{
  brandId: Scalars['UUID'];
}>;


export type GetMetabaseTokenMutation = { __typename: 'Mutation', getMetabaseToken: { __typename: 'GetMetabaseToken', token: string | null } | null };


export const AdminPitchableCampaignAdminSendToContactDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminPitchableCampaignAdminSendToContact"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contactId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendPitchableCampaignToContact"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"pitchableCampaignId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"pitchableCampaignRetailerContactId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contactId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"force"},"value":{"kind":"BooleanValue","value":true}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pitchableCampaignEmailMessageId"}}]}}]}}]} as unknown as DocumentNode<AdminPitchableCampaignAdminSendToContactMutation, AdminPitchableCampaignAdminSendToContactMutationVariables>;
export const AdminPitchableCampaignAdminSendToEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminPitchableCampaignAdminSendToEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"salutation"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendPitchableCampaignToEmailAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"pitchableCampaignId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"emailAddress"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"salutation"},"value":{"kind":"Variable","name":{"kind":"Name","value":"salutation"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailMessageId"}}]}}]}}]} as unknown as DocumentNode<AdminPitchableCampaignAdminSendToEmailMutation, AdminPitchableCampaignAdminSendToEmailMutationVariables>;
export const AdminPitchableCampaignAdminSendCampaignDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminPitchableCampaignAdminSendCampaign"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contactIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendPitchableCampaignToValidDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"pitchableCampaignId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"campaignId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"pitchableCampaignRetailerContactIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contactIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pitchableCampaignEmailMessageIds"}}]}}]}}]} as unknown as DocumentNode<AdminPitchableCampaignAdminSendCampaignMutation, AdminPitchableCampaignAdminSendCampaignMutationVariables>;
export const AdminPitchableEmailInboxForwardToBrandDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminPitchableEmailInboxForwardToBrand"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forwardReceivedPitchableEmailToBrand"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"receivedPitchableEmailMessageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forwardedEmailMessageId"}}]}}]}}]} as unknown as DocumentNode<AdminPitchableEmailInboxForwardToBrandMutation, AdminPitchableEmailInboxForwardToBrandMutationVariables>;
export const AdminPitchableEmailInboxForwardToAddressDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminPitchableEmailInboxForwardToAddress"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forwardReceivedEmailToAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"receivedEmailMessageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"emailAddress"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"via"},"value":{"kind":"StringValue","value":"Pitchable","block":false}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forwardedEmailMessageId"}}]}}]}}]} as unknown as DocumentNode<AdminPitchableEmailInboxForwardToAddressMutation, AdminPitchableEmailInboxForwardToAddressMutationVariables>;
export const AnalyticsProductsMappingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AnalyticsProductsMappings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataSourceProviderName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyticsSkuMappings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataSourceProviderName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataSourceProviderName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"skuComponentTypes"}},{"kind":"Field","name":{"kind":"Name","value":"products"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productName"}},{"kind":"Field","name":{"kind":"Name","value":"upc"}},{"kind":"Field","name":{"kind":"Name","value":"skuComponentId"}},{"kind":"Field","name":{"kind":"Name","value":"extraIdentifiers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AnalyticsProductsMappingsQuery, AnalyticsProductsMappingsQueryVariables>;
export const InviteBrandUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InviteBrandUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inviteBrandUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}}},{"kind":"Argument","name":{"kind":"Name","value":"user"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"brandId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"image"}},{"kind":"Field","name":{"kind":"Name","value":"inviteAcceptedAt"}}]}}]}}]}}]}}]} as unknown as DocumentNode<InviteBrandUserMutation, InviteBrandUserMutationVariables>;
export const ResendBrandUserInviteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResendBrandUserInvite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendBrandUserInvite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ResendBrandUserInviteMutation, ResendBrandUserInviteMutationVariables>;
export const ResetPasswordConfirmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPasswordConfirm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reNewPassword"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPasswordConfirm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}},{"kind":"Argument","name":{"kind":"Name","value":"newPassword"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}}},{"kind":"Argument","name":{"kind":"Name","value":"reNewPassword"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reNewPassword"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;
export const RequestSampleInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RequestSampleInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pceid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pcrcid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pitchableRequestSampleInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pitchableCampaignEmailId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pceid"}}},{"kind":"Argument","name":{"kind":"Name","value":"pitchableCampaignRetailerContactId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pcrcid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandName"}},{"kind":"Field","name":{"kind":"Name","value":"formData"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"retailerName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"line1"}},{"kind":"Field","name":{"kind":"Name","value":"line2"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"zip"}}]}}]}}]}}]} as unknown as DocumentNode<RequestSampleInfoQuery, RequestSampleInfoQueryVariables>;
export const RequestSampleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestSample"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PitchableRequestSampleMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pitchableRequestSample"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<RequestSampleMutation, RequestSampleMutationVariables>;
export const CreatePitchableUnsubscribeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePitchableUnsubscribe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePitchableUnsubscribeMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPitchableUnsubscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<CreatePitchableUnsubscribeMutation, CreatePitchableUnsubscribeMutationVariables>;
export const DeletePitchableUnsubscribeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePitchableUnsubscribe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeletePitchableUnsubscribeMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePitchableUnsubscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<DeletePitchableUnsubscribeMutation, DeletePitchableUnsubscribeMutationVariables>;
export const SendResetEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendResetEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<SendResetEmailMutation, SendResetEmailMutationVariables>;
export const PitchableUnsubscribeVerifyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PitchableUnsubscribeVerify"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"eaid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pcrcid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pitchableUnsubscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailAddressId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"eaid"}}},{"kind":"Argument","name":{"kind":"Name","value":"pitchableCampaignRetailerContactId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pcrcid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandId"}},{"kind":"Field","name":{"kind":"Name","value":"brandName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"emailAddressId"}},{"kind":"Field","name":{"kind":"Name","value":"isUnsubscribed"}},{"kind":"Field","name":{"kind":"Name","value":"isBrandUnsubscribed"}}]}}]}}]} as unknown as DocumentNode<PitchableUnsubscribeVerifyQuery, PitchableUnsubscribeVerifyQueryVariables>;
export const GetTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"auth"},"name":{"kind":"Name","value":"tokenAuth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshExpiresIn"}}]}}]}}]} as unknown as DocumentNode<GetTokenMutation, GetTokenMutationVariables>;
export const RefreshTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefreshToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"payload"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshExpiresIn"}}]}}]}}]} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RevokeTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RevokeToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revokeToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revoked"}}]}}]}}]} as unknown as DocumentNode<RevokeTokenMutation, RevokeTokenMutationVariables>;
export const GetMetabaseTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetMetabaseToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMetabaseToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"brandId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"brandId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}}]}}]}}]} as unknown as DocumentNode<GetMetabaseTokenMutation, GetMetabaseTokenMutationVariables>;