import { useMemo } from "react";

export function useOptionalCombineFilters<T>(
  ...filters: (T | T[] | undefined | null)[]
) {
  return useMemo(() => {
    const combined = filters.reduce<T[]>((acc, filter) => {
      if (!filter) return acc;

      if (Array.isArray(filter)) {
        filter.forEach((f) => {
          acc.push(f);
        });
      } else {
        acc.push(filter);
      }
      return acc;
    }, []);

    if (!combined.length) return;

    if (combined.length === 1) return combined[0];

    return { and: combined };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filters]);
}

export default function useCombineFilters<T>(
  ...filters: (T | T[] | undefined | null)[]
) {
  const combined = useOptionalCombineFilters(...filters);
  if (!combined) {
    return { and: [] as T[] };
  }
  return combined;
}
